<script lang="ts" setup>
import Link1 from "@/components/Button/Link1.vue";
const props = defineProps({
  data: {
    type: Object as () => Record<string, any>, // 指定 data 的類型為非空的物件
    required: true, // 告訴 TypeScript data 是必需的
  },
});
</script>

<template>
  <nuxt-link :to="props.data.link" class="card">
    <figure class="card__pic imh__opacity">
      <NuxtImg :src="props.data.image"></NuxtImg>
    </figure>
    <div class="card__item">
      <div class="card__item__top">
        <div class="card__titleEn" v-html="props.data.titleEn || ''">
        </div>
        <div class="card__titleTw" v-html="props.data.titleTw || ''">
        </div>
      </div>
      <div class="card__item__bottom">
        <DialogCoverVue class="card__textEn">
          <div v-html="data.textEn || ''"></div>
        </DialogCoverVue>
        <div class="card__textTw">
          <div v-html="data.textTw || ''"></div>
        </div>
        <Link1 link="/opinion" />
      </div>
    </div>
  </nuxt-link>
</template>

<style lang="scss" scoped>
.card {
  display: flex;

  @include max-media(480) {
    flex-direction: column;
  }

  &__pic {
    width: 40%;

    @include max-media(480) {
      width: 100%;
      max-width: 123px;
      margin-left: auto;
      margin-right: auto;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__item {
    width: 60%;
    // padding-top: 25px;
    padding-left: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      opacity: 0.7;
    }

    @include max-media(767) {
      padding-left: 40px;
    }

    @include max-media(480) {
      width: 100%;
      padding-left: 0;
      text-align: center;
      padding-top: 25px;
    }

    &__top {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid rgba(#000, 0.48);
    }
  }

  &__titleEn {
    display: block;
    font-size: 16px;
    line-height: 1.11;
    margin-bottom: 15px;

    @include max-media(480) {
      font-size: 8px;
    }
  }

  &__titleTw {
    display: block;
    font-size: 16px;
    line-height: 1.11;

    @include max-media(480) {
      font-size: 10px;
    }
  }

  &__textEn {
    display: block;
    margin-bottom: 20px;
    font-size: 14px;
    font-family: "Times New Roman";
    font-style: italic;
    line-height: 1.49;

    @include max-media(480) {
      font-size: 8px;
    }
  }

  &__textTw {
    display: block;
    margin-bottom: 50px;
    font-size: 15px;
    line-height: 1.49;

    @include max-media(480) {
      font-size: 10px;
      margin-bottom: 25px;
    }
  }
}
</style>
